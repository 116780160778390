<template>
  <div class="home">
    <Bar>
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="search">
            <el-input
              v-model="queryParams.params.customerPhone"
              placeholder="请输入搜索关键字"
              @keyup.enter.native="search"
            >
              <i
                slot="prefix"
                class="el-input__icon el-icon-search search-btn"
                @click.stop="search"
              />
            </el-input>
          </div>
          <el-button
            round
            class="add-btn linear-btn"
            @click="$router.push('/addUser')"
          >
            <i class="el-icon-circle-plus-outline add-icon" />
            新建激活申请
          </el-button>
          <span class="label select-linear">
            <span>状态:</span>
            <el-select
              v-model="queryParams.params.customerStatus"
              placeholder="请选择"
              @change="search"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </span>
        </el-col>
      </el-row>
    </Bar>
    <div class="panel">
      <el-table
        ref="outTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        />
        <el-table-column
          label="序号"
          width="80"
          prop="index"
          sortable
        />
        <el-table-column
          prop="childrenName"
          label="测评宝宝"
          width="150"
          sortable
        />
        <el-table-column
          prop="customerPhone"
          label="监护人电话"
          align="center"
          width="150"
          sortable
        />
        <el-table-column
          prop="childrenBirthday"
          label="宝宝出生日期"
          align="center"
          width="150"
          sortable
        />
        <el-table-column
          prop="childrenSex"
          label="宝宝性别"
          align="center"
          width="150"
          :formatter="(row, col, value, index)=>sexConvert(value)"
          sortable
        />
        <el-table-column
          prop="activeTimes"
          label="激活次数"
          align="center"
          width="150"
          :formatter="(row,col,value,index)=>value||0"
        />
        <el-table-column
          align="center"
          width="180"
          label="创建时间"
          prop="createTime"
          :formatter="(row,col,value,index)=>dateFormat('YYYY-mm-dd HH:MM',value)"
          sortable
        />
        <el-table-column
          align="center"
          width="180"
          label="更新时间"
          prop="updateTime"
          :formatter="(row,col,value,index)=>dateFormat('YYYY-mm-dd HH:MM',value)"
          sortable
        />
        <el-table-column
          prop="customerStatus"
          label="状态"
          align="center"
          width="150"
          show-overflow-tooltip
        >
          <div
            slot-scope="scope"
            class="status"
          >
            <span
              class="status-circle"
              :style="`background:${getStatus(scope.row.customerStatus).color}`"
            />
            <span class="status-text">{{ getStatus(scope.row.customerStatus).label }}</span>
          </div>
        </el-table-column>
        <el-table-column
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.customerStatus===0"
              type="text"
              size="small"
              @click.stop="$router.push({
                path: `/editUser/${scope.row.id}`,
                query: {isActivePage:true}
              })"
            >
              编辑
            </el-button>
            <el-button
              v-else
              type="text"
              size="small"
              @click.stop="$router.push({
                path: `/editUser/${scope.row.id}`,
                query: {disableEdit: true,isActivePage:true}
              })"
            >
              查看
            </el-button>
            <el-popconfirm
              v-if="scope.row.customerStatus===0"
              title="确定要删除吗？"
              style="margin-right:10px"
              @confirm="deleteUser(scope.row)"
            >
              <el-button
                slot="reference"
                type="text"
                size="small"
              >
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination text-right">
        <el-pagination
          background
          layout="prev, pager, next"
          hide-on-single-page
          :current-page="queryParams.current"
          :page-size="queryParams.size"
          :total="pageTotal"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <QrLayer />
  </div>
</template>

<script>
import {
  deleteUser, getActiveCustomerList
} from '@/api';
import { dateFormat } from '@/utils/utils';

export default {
  name: 'Home',
  components: {
    QrLayer: () => import('../home/qrLayer.vue')
  },
  data() {
    return {
      // 激活状态 0:待激活; 1:已激活 2:已测评
      options: [
        { label: '全部', value: undefined, color: '#666' },
        { label: '待激活', value: 0, color: '#FFA14A' },
        { label: '已激活', value: 1, color: '#87D068' },
        // { label: '已测评', value: 2, color: '#78DBD9' },
      ],
      tableData: [],
      multipleSelection: [],
      queryParams: {
        params: {
          customerPhone: '',
          customerStatus: undefined
        },
        current: 1,
        size: 10
      },
      pageTotal: 0,
      childData: []
    };
  },
  created() {
    this.init();
  },
  methods: {
    dateFormat,
    sexConvert(value) {
      const sexMap = {
        0: '未知',
        1: '男',
        2: '女'
      };
      return sexMap[value] || '-';
    },
    init() {
      this.search();
    },
    search() {
      this.queryParams.current = 1;
      this.getActiveCustomerList();
    },
    // 查询激活评测用户列表
    getActiveCustomerList() {
      getActiveCustomerList(this.queryParams).then((res) => {
        this.tableData = (res?.data?.records || []).map((item, index) => {
          const dataIndex = (this.queryParams.current - 1) * 10 + index + 1;
          return {
            ...item,
            index: dataIndex,
            list: []
          };
        });
        this.pageTotal = res.data?.total || 0;
      });
    },
    // table勾选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页选择
    handleCurrentChange(val) {
      this.queryParams.current = val;
      this.getActiveCustomerList();
    },
    // 测评用户状态
    getStatus(status) {
      const cur = this.options.find((item) => status === item.value);
      return cur;
    },
    // 删除用户
    deleteUser(item) {
      const params = {
        id: item.id
      };
      deleteUser(params).then((res) => {
        this.$message.warning(res.msg);
        setTimeout(() => {
          this.getActiveCustomerList();
        }, 1500);
      }, (err) => {
        this.$message.warning(err.msg);
      });
    },
    // 激活用户
    activeUser() {
      this.$message.success('用户激活成功！');
    },
  },
};
</script>
<style lang="less">
.el-button+.el-button{
  margin-left: 0;
}
.el-button{
  &:not(:last-child){
    margin-right: 10px;
  }
}
</style>

<style lang="less" scoped>
.search{
  width: 306px;
  display: inline-block;
  margin-right: 12px;
  &-btn{
    color: #000;
    font-size: 20px;
    line-height: 42px;
    padding: 0 5px;
    font-weight: 600;
    cursor: pointer;
  }
  /deep/ .el-input__inner{
    border-radius: 40px;
    padding-left: 41px;
    font-size: 16px;
    color: #000;
    &:focus{
      border-color: #85C6CE;
    }
  }
}
.label{
  font-size: 16px;
  margin-left: 20px;
}
.add-btn{
  padding-left: 10px;
  padding-right: 10px;
}
.add-icon{
  font-size: 20px;
  vertical-align: middle;
}
.panel{
  margin-top: 16px;
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 16px;
  /deep/.el-table {
    color:#000;
    thead .cell{
      color: #000;
      font-weight: 400;
    }
  }
  .status{
    padding-left: 26px;
    position: relative;
    display: inline-block;
    &-circle{
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      margin-top: -6px;
      height: 12px;
      width: 12px;
      border-radius: 12px;
      background: #333;
    }
  }
  .pagination{
    margin-top: 16px;
  }
  .report-box{
    background: #f8f8f8;
    padding: 0 50px 0 100px;
    /deep/.el-table,/deep/.el-table th, /deep/.el-table tr,/deep/.el-table__expanded-cell{
      background: #f8f8f8;
    }
  }
  /deep/.el-table__expanded-cell[class*="cell"]{
    padding: 0;
  }
}
</style>
